@import "../../scss/global";

@value confetti: "#{$color-brand-accent},#{"" + $color-mono-100},#{$color-brand-primary},#{lighten($color-brand-primary, 10%)},#{lighten($color-brand-grad-start, 20%)},#{lighten($color-brand-grad-end, 20%)}";

.Wrapper {
  &-question {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-correct,
  &-incorrect {
    text-align: center;
    margin: auto;
  }
}

.StateAnswer {
  margin: 0 auto;
}

.QuestionCard {
  flex-grow: 1;
  max-width: var(--container-thin-width);
  width: 100%;
  margin: auto;
  max-height: calc(var(--container-thin-width) * 1.25);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.QuestionStreak {
  position: absolute;
  top: 0;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  z-index: 2;

  strong {
    margin-left: rem(8);
  }

  &-hidden {
    display: none;
  }
}
.QuestionEmojis {
  --emoji-modifier: 1;
  --emoji-size: #{rem(24)};
  --emoji-width: calc((var(--emoji-size)) * var(--emoji-modifier));
  --emoji-gap: calc(var(--emoji-width) / 5);

  @include min-width(360) {
    --emoji-size: calc(var(--container-thin-width) / 8);
    --emoji-width: calc(
      (var(--container-thin-width) / 8) * var(--emoji-modifier)
    );
  }

  align-items: center;
  margin: 0;
  display: flex;
  line-height: 1;
  gap: var(--emoji-gap);
  width: calc(100% - (var(--emoji-gap) * 2));
  flex-wrap: wrap;
  min-height: calc(var(--emoji-width) * 2);
  justify-content: center;
  font-size: var(--emoji-width);

  &[data-emoji-count="1"] {
    --emoji-modifier: 3;
  }
  &[data-emoji-count="2"] {
    --emoji-modifier: 2;
  }
  &[data-emoji-count="3"] {
    --emoji-modifier: 1.5;
  }
  &[data-emoji-count="4"] {
    --emoji-modifier: 1.25;
  }
}

$unstack-buttons: 340;

.Fieldset {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: calc(var(--gap) * 2) var(--gap);
  align-items: center;
  max-width: var(--container-thin-width);
  width: 100%;

  @include max-width($unstack-buttons) {
    grid-template-columns: 1fr auto auto 1fr;
  }
}
.FieldsetHint {
  height: rem($large-button-min-height);
  width: rem($large-button-min-height);

  @include max-width($unstack-buttons) {
    grid-column: 2;
  }
}

.Form {
  display: grid;
  grid-template-columns: 1fr rem($large-button-min-height);
  font-size: var(--paragraph-size);
  flex: 1;
  width: 100%;

  @include max-width($unstack-buttons) {
    grid-row: 1;
    grid-column: 1 / span 4;
  }
}

.FormInput {
  box-shadow: rgba($color-mono-600, 0.1) 0 rem(1) rem(5);
  font-size: inherit;
  border-radius: rem(42);
  outline: none;
  height: rem($large-button-min-height);
  padding-top: 0.05em;
  padding-bottom: 0;
  padding-right: rem($large-button-min-height);
  padding-left: rem($large-button-min-height / 2);
  border: 0;
  flex: 1;
  width: 100%;
  grid-column: 1 / span 2;
  grid-row: 1;
  transition: box-shadow $duration-default $easing-default;

  &:focus {
    box-shadow: rgba($color-mono-600, 0.2) 0 rem(2) rem(10);
  }

  &::placeholder {
    color: $color-mono-300;
  }

  &-wrong {
    animation: shake 250ms linear;

    @keyframes shake {
      0%,
      20%,
      40%,
      60%,
      80%,
      100% {
        transform: none;
      }
      10%,
      50%,
      90% {
        transform: translateX(2%);
      }
      30%,
      60% {
        transform: translateX(-2%);
      }
    }
  }
}

.FormButton {
  background: none;
  outline: none;
  border: 0;
  grid-column: 2;
  grid-row: 1;
  font-size: inherit;
  width: rem($large-button-min-height);
  height: rem($large-button-min-height);
  padding: 0;
  min-height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: $color-mono-500;
  transition: color $duration-default $easing-default;

  path {
    fill: currentColor;
  }

  &:hover,
  &:focus {
    color: $color-brand-primary;
  }
}

.Buttons {
  text-align: center;

  @include min-width($unstack-buttons) {
    grid-column: 1 / span 2;
  }
}

.Hint {
  margin: var(--gap) 0 0;
}
.HintName {
  display: block;
  margin-bottom: 0.25em;
  margin-top: -0.25em; // Rebalances the card padding
  font-weight: $font-weight-body-medium;
}
.HintIntro {
  text-align: center;
  font-weight: $font-weight-body-medium;
  margin-bottom: calc(var(--gap) * 2);
}
.HintText {
  margin: 0;
}
.HintButton {
  > span {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
}
