@import "../../scss/global";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  --gap: #{rem(12)};
  --bottom-padding: calc(env(safe-area-inset-bottom) + (var(--gap) * 2));

  --paragraph-size: #{rem(16)};
  --h1-size: #{rem(32)};
  --h2-size: #{rem(26)};
  --h3-size: #{rem(22)};
  --h4-size: #{rem(20)};

  --container-default-width: #{rem(520)};
  --container-thin-width: #{rem(320)};

  @include min-width(480) {
    --gap: #{rem(16)};

    --paragraph-size: #{rem(17)};
    --h1-size: #{rem(36)};
    --h2-size: #{rem(32)};
    --h3-size: #{rem(26)};
    --h4-size: #{rem(24)};

    --container-thin-width: #{rem(360)};
  }

  @include min-width(768) {
    --gap: #{rem(20)};

    --paragraph-size: #{rem(18)};
    --h1-size: #{rem(44)};
    --h2-size: #{rem(36)};
    --h3-size: #{rem(30)};
    --h4-size: #{rem(26)};

    --container-thin-width: #{rem(420)};
  }

  background: $color-brand-grad-start;
  background-size: cover;
  color: $color-mono-100;
  height: min-content;
  min-height: calc(100% + env(safe-area-inset-top));
  font-size: 100%;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) 0
    env(safe-area-inset-left);

  &::before {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    content: "";
    background: url(../../assets/images/background.svg) center top,
      linear-gradient($color-brand-grad-start, $color-brand-grad-end 70%);
    height: 100%;
  }

  &::after {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: env(safe-area-inset-top);
    backdrop-filter: blur(10px);
    content: "";
    z-index: 40;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  line-height: $line-height-body;
  font-family: $font-family-body;
  margin: 0;
  position: relative;
}

p {
  font-size: var(--paragraph-size);
}

// This structure is required to make the browser overscroll feel like a native
// app on iOS. It leaves a kinda janky feel on desktop, but it's a necessary trade.
:global(#root) {
  position: fixed;
  height: 100%;
  overflow: auto;
  top: 0;
  display: flex;
  flex-direction: column;
  -webkit-text-size-adjust: none;
  touch-action: pan-y;
  width: 100%;
  width: calc(100% - env(safe-area-inset-left) - env(safe-area-inset-right));
}

a {
  color: $color-brand-accent;
}

img,
svg {
  display: block;
}

:global(.sr-only) {
  @include sr-only;
}

/* prettier-ignore */
@at-root {
  :global(.mb) { margin-bottom: var(--gap); }
  :global(.mt) { margin-top: var(--gap); }

  :global(.mb0) { margin-bottom: 0; }
  :global(.mt0) { margin-top: 0; }

  :global(.mt2) { margin-top: calc(var(--gap) * 2); }
  :global(.mb2) { margin-bottom: calc(var(--gap) * 2); }
}

.Loader {
  font-weight: $font-weight-body-medium;
  padding: 0 rem(20) 0.25em; // 0.25em visually offsets the elipsis
  margin: auto;
}

.Nav,
.Points {
  height: rem($nav-height);
  display: grid;
  position: fixed;
  top: calc(env(safe-area-inset-top) + #{rem($nav-margin)});
  left: 0;
  width: 100%;
  pointer-events: none;
  z-index: $modal-z-index - 1;

  > div {
    grid-area: 1 / 1;
    display: flex;
    justify-content: flex-end;
    margin: auto;

    > * {
      pointer-events: initial;
    }
  }
}
.Points {
  z-index: $modal-z-index + 1;

  > div {
    justify-content: flex-start;
  }
}
.NavButton {
  --button-background: #{rgba($color-brand-grad-start, 0.5)};
}

.UpdateToast {
  background: linear-gradient(
    to top,
    $color-brand-grad-end,
    rgba($color-brand-grad-end, 0.01)
  );
  position: fixed;
  left: 0;
  text-align: center;
  padding-bottom: calc(env(safe-area-inset-bottom) + var(--gap));
  bottom: 0;
  padding-top: calc(var(--gap) * 2);
  width: 100%;
  pointer-events: none;
  z-index: $modal-z-index + 50;
  transform: translateY(100%);
  transition: transform $duration-default $easing-default;

  &-active {
    transform: none;
  }

  > * {
    pointer-events: auto;
  }
}
