@import "../../scss/global";

.Wrapper {
  background: linear-gradient(
    rgba(darken($color-brand-grad-start, 20%), 0.8),
    rgba(darken($color-brand-grad-end, 20%), 0.8) 70%
  );
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: $modal-z-index;
  overscroll-behavior: none;
  padding: rem($nav-height) 0 0;
  padding-bottom: calc(
    var(--bottom-padding) + #{rem(32)} + (var(--gap) * 2)
  ); // TODO: Var 32
}

.Container {
  > :first-child {
    margin-top: calc(var(--gap) * 2);
  }
}

.Buttons {
  position: absolute;
  text-align: center;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: var(--bottom-padding);
}
