@import "../../scss/global";

.Wrapper {
  margin: auto;

  p {
    margin: 0 auto 2em;
    max-width: 19em; // Separates the lines in an aesthetic way
    font-weight: $font-weight-body-medium;
  }
}

.Poster {
  margin: 0 auto !important;
  width: 80%;
  position: relative;

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  span {
    background: #fff3de; // Non-var colour as this is a design element
    width: 15%;
    aspect-ratio: 3/1;
    display: block;
    position: absolute;
    opacity: 0.8;

    &:nth-of-type(1) {
      top: 0;
      left: 0;
      transform: rotate(-30deg) translate(-20%, -30%);
    }

    &:nth-of-type(2) {
      top: 0;
      right: 0;
      transform: rotate(40deg) translate(20%, -30%);
    }

    &:nth-of-type(3) {
      bottom: 0;
      left: 0;
      transform: rotate(42deg) translate(-20%, 50%);
    }
    &:nth-of-type(4) {
      bottom: 0;
      right: 0;
      transform: rotate(-25deg) translate(20%, 30%);
    }
  }
}

.Title {
  margin: 1em auto 0.5em;
}
