$font-family-heading: "Paytone One", sans-serif;
$font-family-body: "Open Sans", sans-serif;

$font-weight-heading: 400;
$font-weight-body-regular: 400;
$font-weight-body-medium: 600;
$font-weight-body-bold: 700;

$line-height-heading: 1.2;
$line-height-body: 1.45;
$line-height-body-large: 1.3;

$color-mono-600: black;
$color-mono-500: #2b2b2b;
$color-mono-100: white;
$color-mono-400: mix($color-mono-500, $color-mono-100, 80%);
$color-mono-300: mix($color-mono-500, $color-mono-100, 50%);
$color-mono-200: mix($color-mono-500, $color-mono-100, 30%);

$color-brand-accent: #fff172;
$color-brand-primary: #8335ef;
$color-brand-grad-start: #6f3aec;
$color-brand-grad-end: #982ff4;

$duration-default: 200ms;
$duration-fast: 100ms;

$easing-default: ease-in-out;

$modal-z-index: 900;

$nav-height: 36;
$nav-margin: 8;

$medium-button-font-size: 14;
$medium-button-line-height: 14;
$medium-button-vertical-padding: 4;
$medium-button-min-height: ($medium-button-line-height * 2) +
  ($medium-button-vertical-padding * 2);

$large-button-font-size: 17;
$large-button-line-height: 18;
$large-button-vertical-padding: 6;
$large-button-min-height: ($large-button-line-height * 2) +
  ($large-button-vertical-padding * 2);
