@import "../../scss/global";

.Card {
  background: $color-mono-100;
  box-shadow: rgba($color-mono-600, 0.1) 0 rem(1) rem(5);
  color: $color-mono-600;
  border-radius: rem(8);
  padding: var(--gap);

  &-center {
    text-align: center;
  }
}
