@import "../../scss/global";

.Button {
  background: none;
  font-family: $font-family-body;
  font-weight: $font-weight-body-medium;
  display: inline-flex;
  width: fit-content;
  border: 0;
  cursor: pointer;
  color: var(--button-color, currentColor);
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  transition: color $duration-default $easing-default,
    transform $duration-fast $easing-default;
  vertical-align: top;
  text-decoration: none;

  &::before {
    background: var(--button-background);
    border: 0.125em solid transparent;
    box-shadow: rgba($color-mono-600, 0.05) 0 0.5em 0.5em;
    border-radius: 5rem;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: $duration-default $easing-default;
    transition-property: background-color, box-shadow, opacity;
  }

  &:not([disabled]) {
    &:hover {
      &::before {
        background: var(--button-background-hover);
        box-shadow: rgba($color-mono-600, 0.1) 0 0.8em 0.8em;
      }
    }
    &:focus-visible {
      &::before {
        box-shadow: rgba($color-mono-600, 0.2) 0 0.8em 0.8em;
      }
    }
    &:focus {
      outline: none;
      border: 0;
    }
    &:active {
      transform: scale(0.95);
    }
  }

  &[disabled]:not(.Button-loading) {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.6;

    &::before {
      background: var(--button-background-disabled, currentColor);
      opacity: 0.2;
      box-shadow: none !important;
    }
  }

  path {
    fill: currentColor;
  }
}

.Children {
  position: relative;
}

.Button {
  &-accentColor {
    --button-background: #{$color-brand-accent};
    --button-background-hover: #{$color-mono-100};
    --button-color: #{$color-mono-600};
  }

  &-lightOutlineColor {
    --button-background: transparent;
    --button-background-hover: #{rgba($color-mono-600, 0.2)};
    --button-background-disabled: transparent;

    &::before {
      border-color: currentColor;
    }
  }

  &-backdropBlur {
    &::before {
      backdrop-filter: blur(5px);
    }
  }
}

.Button {
  &-smallSize {
    font-size: rem(12);
    line-height: rem(12);
    padding: rem(2) rem(14);
    min-height: rem((12 + 2) * 2);

    &::before {
      border-width: rem(1);
    }
  }

  &-mediumSize {
    font-size: rem($medium-button-font-size);
    line-height: rem($medium-button-line-height);
    padding: rem($medium-button-vertical-padding) rem(20);
    min-height: rem($medium-button-min-height);

    &.Button-iconOnly {
      min-width: rem($medium-button-min-height);
      padding: 0;
    }

    &::before {
      border-width: rem(1.5);
    }
  }

  &-largeSize {
    font-size: rem($large-button-font-size);
    line-height: rem($large-button-line-height);
    padding: rem($large-button-vertical-padding) rem(32);
    min-height: rem($large-button-min-height);

    &::before {
      border-width: rem(2);
    }
  }
}

.Button {
  &-loading {
    cursor: progress;

    .Children {
      animation: loading 2s infinite linear;

      @keyframes loading {
        0%,
        100% {
          opacity: 0.5;
        }
        25%,
        75% {
          opacity: 1;
        }
      }
    }
  }

  &-block {
    width: var(--container-thin-width);
    max-width: 100%;
  }
}
