@import "../../scss/global";

$temp-gap: 1ch;
$temp-right-gap: 0.5ch;
$number-width: 1ch;
$comma-width: 0.33ch;

.Number {
  transition: width $duration-fast $easing-default;
  width: calc(
    #{$temp-gap} + (#{$number-width} * var(--points-numbers)) + (#{$comma-width} *
          var(--points-commas))
  );
  margin-right: $temp-right-gap;
  text-align: right;
  font-variant-numeric: tabular-nums;
  display: flex;
  justify-content: flex-end;
}

.Pill {
  background: $color-mono-100;
  color: $color-mono-600;
  border-radius: rem(50);
  align-items: center;
  padding: 0 calc(#{rem(8)} + #{$temp-right-gap});
  height: rem($medium-button-min-height);
  font-weight: $font-weight-body-medium;
  font-size: rem(16);
  display: flex;
  width: fit-content;
  text-decoration: none;
  overflow: hidden;
  border: 0;
  box-shadow: rgba($color-mono-600, 0.1) 0 rem(1) rem(5);

  &-animating {
    animation: points $duration-default * 2 linear;

    @keyframes points {
      0%,
      100% {
        transform: scale(1);
      }
      20% {
        transform: scale(1.1);
      }
    }
  }
}

.Coin {
  background: url("../../assets/images/coin.svg");
  display: block;
  width: rem(18);
  height: rem(18);
}
