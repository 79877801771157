@import "../../scss/global";

.SectionTitle {
  margin-top: 3em;
}

.Headings {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  padding-left: var(--gap);

  > * {
    margin: 0;
  }
}

.Buttons {
  display: flex;
  gap: calc(var(--gap) / 2);
  flex-wrap: wrap;
  align-items: flex-start;
}
