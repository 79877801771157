@import "../../scss/global";

.Heading {
  font-family: $font-family-heading;
  font-weight: $font-weight-heading;
  line-height: $line-height-heading;
  margin: 1.5em auto 0.5em;

  &-center {
    text-align: center;
  }
}

.Heading {
  &-h1 {
    font-family: $font-family-heading;
    color: $color-mono-500;
    letter-spacing: 0.025em;
    position: relative;
    font-size: var(--h1-size);
    text-shadow: rgba($color-mono-600, 0.5) 0 0 0.5em;
    word-spacing: 0.125em;
    margin-bottom: 0.75em;

    &::before,
    &::after {
      content: attr(aria-label);
      position: absolute;
      left: 0;
      top: 0;
      text-align: inherit;
      width: 100%;
      text-shadow: none;
      pointer-events: none;
    }

    &::before {
      -webkit-text-stroke: 0.2em $color-mono-100;
      user-select: none;
    }

    &::after {
      color: $color-mono-500;
    }
  }

  &-h2 {
    font-size: var(--h2-size);
  }

  &-h3 {
    font-size: var(--h3-size);
  }

  &-h4 {
    font-size: var(--h4-size);
    font-family: $font-family-body;
    font-weight: $font-weight-body-medium;
    line-height: $line-height-body-large;
  }
}
