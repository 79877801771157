@import "../../scss/global";

.Container {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - (var(--gap) * 2));

  &-thinWidth {
    max-width: var(--container-thin-width);
  }

  &-defaultWidth {
    max-width: var(--container-default-width);
  }

  &-center {
    text-align: center;
  }
}

main.Container {
  padding-bottom: var(--bottom-padding);
  padding-top: calc(
    env(safe-area-inset-top) + #{rem($nav-height + $nav-margin)}
  );

  > :first-child {
    margin-top: calc(var(--gap) * 2);
  }
}
