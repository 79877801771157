@import "../../scss/global";

.Wrapper {
  text-align: center;
  margin: auto;
}

.Stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: calc(var(--gap) / 2);
  max-width: var(--container-thin-width);
  margin: 0 auto;
}

.Stat {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 0;
  aspect-ratio: 3/2;

  &:last-child:nth-child(2n-1) {
    grid-column: span 2;
  }
}
.StatNumber {
  margin: 0;
  color: $color-mono-500;
}
.StatLabel {
  margin: 0;
  font-weight: $font-weight-body-medium;
}

.Buttons {
  display: flex;
  gap: var(--gap);
  justify-content: center;

  strong {
    display: inline-block;
    width: 3ch;
  }

  abbr {
    border: 0;
    text-decoration: none;
  }
}
